<template lang="pug">
  .app-container.roles-list
    .filter-container
      el-button(class="filter-item" style="margin-left: 10px;" type="primary" icon="el-icon-plus" @click="handleCreate") Создать
      //- el-button(v-waves :loading="downloading" class="filter-item" type="primary" icon="el-icon-download" @click="handleDownload") Экспорт

    el-table(v-loading="loading" :data="list" border fit highlight-current-row style="width: 100%")
      el-table-column(align="center" label="ID" width="80")
        template(slot-scope="scope")
          span {{ scope.row.id }}

      el-table-column(align="center" label="Имя")
        template(slot-scope="scope")
          span {{ scope.row.name }}

      el-table-column(align="center" label="Разрешений")
        template(slot-scope="scope")
          span {{ scope.row.permissions.length }}

      el-table-column(align="center" label="Действия" width="400")
        template(slot-scope="scope")
          el-button(type="primary" size="small" icon="el-icon-edit" v-permission="['manage admin__permissions']" @click="handleEdit(scope.row.id)") Изменить
          el-button(type="info" size="small" icon="el-icon-copy-document" v-permission="['manage admin__permissions']" @click="handleDuplicate(scope.row.id)") Копировать
          //- v-if="!scope.row.roles.includes('admin')"
          //- el-button.roles-list__action(type="warning" size="small" icon="el-icon-edit" v-permission="['manage permission']" @click="handleEditPermissions(scope.row.id)") Роли
          //- v-if="scope.row.roles.includes('visitor')"
          el-button.roles-list__action(type="danger" size="small" icon="el-icon-delete" v-permission="['manage admin__permissions']"  @click="handleDelete(scope.row.id, scope.row.name)") Удалить

    pagination(v-show="total>0" :total="total" :page.sync="query.page" :limit.sync="query.limit" @pagination="getList")

    el-dialog.roles-list__dialog(:visible.sync="dialogEditVisible" :title="'Изменить роль - ' + currentRole.name")
      .form-container(v-loading="dialogEditLoading" v-if="currentRole.name")
        el-form(ref="editForm" :rules="rules" :model="currentRole" label-position="left" label-width="150px")
          el-form-item(label="Имя" prop="name")
            el-input(v-model="currentRole.name")

          div(style="position: relative; height: 500px; overflow-y: scroll; margin-bottom: 20px")
            h4.condition-field__wide-title Разрешения
            .user-create-page__permissions-wrapper
              user-permissions(v-model="currentRole.permissions")

        .dialog-footer(slot="footer")
          el-button(type="danger" @click="dialogEditVisible=false") Отменить

          el-button(type="primary" @click="confirmEdit") Сохранить

    el-dialog.roles-list__dialog(:title="'Создать роль'" top="100px" :visible.sync="dialogCreateVisible")
      .form-container(v-loading="dialogCreateLoading")
        el-form(ref="createForm" :rules="rules" :model="newRole" label-position="left" label-width="150px")
          el-form-item(label="Имя" prop="name")
            el-input(v-model="newRole.name")

          div(style="position: relative; height: 500px; overflow-y: scroll; margin-bottom: 20px")
            h4.condition-field__wide-title Разрешения
            .user-create-page__permissions-wrapper
              user-permissions(v-model="newRole.permissions")


        .dialog-footer(slot="footer")
          el-button(@click="dialogCreateVisible = false") Отменить

          el-button(type="primary" @click="confirmCreate") Сохранить

</template>
<script>
  import Pagination from '@/components/Pagination' // Secondary package based on el-pagination
  import Resource from '@/api/resource'
  import waves from '@/directive/waves' // Waves directive
  import permission from '@/directive/permission' // Waves directive
  import checkPermission from '@/utils/permission' // Permission checking
  import UserPermissions from '@/views/users/UserPermissions'
  import { notifyRequest } from '@/utils/api'

  const roleResource = new Resource('roles')


  export default {
    name: 'PermissionList',
    components: { Pagination, UserPermissions },
    directives: { waves, permission },
    data() {
      return {
        list: null,
        total: 0,
        loading: true,
        downloading: false,
        query: {
          page: 1,
          limit: 15,
          keyword: '',
          role: '',
        },
        roles: [],
        newRole: {},
        dialogCreateLoading: false,
        dialogCreateVisible: false,
        dialogEditVisible: false,
        dialogEditLoading: false,
        currentRoleId: 0,
        currentRole: {
          id: 0,
          name: '',
          roles: [],
        },
        rules: {
          name: [{ required: true, message: 'Имя - обязательное поля', trigger: 'blur' }]
        },
        permissions: [],
        rolePermissions: [],
      }
    },
    computed: {
    },
    created() {
      this.resetnewRole()
      this.getList()
      // if (checkPermission(['manage permission'])) {
      //   this.getPermissions();
      // }
    },
    methods: {
      checkPermission,
      reload() {
        if (this.total === this.query.limit * (this.query.page - 1)) {
          this.query.page--
        }
        this.getList()
      },

      async getList() {
        const { limit, page } = this.query
        this.loading = true
        const { data, meta, roles } = await roleResource.list(this.query)
        this.list = data
        this.list.forEach((element, index) => {
          element['index'] = (page - 1) * limit + index + 1
        })
        this.roles = roles
        this.total = meta.total
        this.loading = false
      },
      handleFilter() {
        this.query.page = 1
        this.getList()
      },
      handleCreate() {
        this.resetnewRole()
        this.dialogCreateVisible = true
        this.$nextTick(() => {
          this.$refs['createForm'].clearValidate()
        })
      },
      handleDelete(id, name) {
        this.$confirm('Данное действие навсегда удаляет роль ' + name + '. Продолжить?', 'Warning', {
          confirmButtonText: 'Подтвердить',
          cancelButtonText: 'Отменить',
          type: 'warning',
        }).then(() => {
          roleResource.destroy(id).then(response => {
            this.$message({
              type: 'success',
              message: 'Удаление успешно',
            })
            this.reload()
          }).catch(error => {
            console.log(error)
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: 'Удаление отменено',
          })
        })
      },
      async handleEdit(id) {
        this.currentRoleId = id
        this.dialogEditLoading = true
        this.dialogEditVisible = true
        const found = this.list.find(permission => permission.id === id)
        this.currentRole = {
          name: found.name,
          permissions: found.permissions.map(el => el.name)
        }
        // this.currentRole = {
        //   name: found.name,
        //   roles: found.roles
        // };
        // const { data } = await roleResource.get(id);
        // this.permissions = data;
        this.dialogEditLoading = false
        this.$nextTick(() => {
          // this.$refs.menuPermissions.setCheckedKeys(this.permissionKeys(this.userMenuPermissions));
          // this.$refs.otherPermissions.setCheckedKeys(this.permissionKeys(this.userOtherPermissions));
        })
      },
      handleDuplicate(id) {
        let item = this.list.filter(el => {
          return el.id === id
        })
        if (!item || !item.length) {
          return
        }
        item = item[0]
        this.$confirm(`Копировать "${item.name}" (ID: ${item.id})?`, 'Подтвердите копирование', {
          confirmButtonText: 'Подтвердить',
          cancelButtonText: 'Отменить',
          // type: 'warning'
        }).then(() => {
          this.duplicateItem(id)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: 'Копирование отменено'
          })
        })
      },
      async duplicateItem(id) {
        const response = await notifyRequest({
          url: `/roles/${id}/duplicate`,
          method: 'post'
        }, 'Роль успешно скопирована')
        this.reload()
        return response
      },
      resetnewRole() {
        this.newRole = {
          name: '',
          roles: [],
        }
      },
      handleDownload() {
        this.downloading = true
        /* import('@/vendor/Export2Excel').then(excel => {
          const tHeader = ['id', 'user_id', 'name', 'email', 'role'];
          const filterVal = ['index', 'id', 'name', 'email', 'role'];
          const data = this.formatJson(filterVal, this.list);
          excel.export_json_to_excel({
            header: tHeader,
            data,
            filename: 'user-list',
          });
          this.downloading = false;
        });*/
      },
      formatJson(filterVal, jsonData) {
        return jsonData.map(v => filterVal.map(j => v[j]))
      },

      confirmEdit() {
        this.dialogEditLoading = true

        roleResource.update(this.currentRoleId, this.currentRole).then(response => {
          this.$message({
            message: 'Роль успешно обновлена',
            type: 'success',
            duration: 5 * 1000
          })
          this.dialogEditLoading = false
          this.dialogEditVisible = false
          this.getList()
        }).catch(() => {
          this.dialogEditLoading = false
        })
      },

      confirmCreate() {
        this.dialogCreateLoading = true

        roleResource.store(this.newRole).then(response => {
          this.$message({
            message: 'Роль успешно создана',
            type: 'success',
            duration: 5 * 1000,
          })
          this.dialogCreateLoading = false
          this.dialogCreateVisible = false
          this.getList()
        }).catch(() => {
          this.dialogCreateLoading = false
        })
      }
    },
  }
</script>

<style lang="sass">
  @import "@/assets/sass/blocks/roles-list.sass"
</style>
